import { getRequest,postRequest,postFormReq,deleteRequest } from "@/http/request_service.js";
import { API } from "@/api/api.js"

// 獲取bnner列表
export const getBannerList=(params)=>{
    return getRequest(API.bannerList,params);
}
// banner詳情
export const getBannerDetails=(params)=>{
    return getRequest(API.bannerList+'/'+params);
}
// banner刪除
export const deleteBanner=(params)=>{
    return deleteRequest(API.bannerList+'/'+params);
}
// 編輯bnner
export const bannerEdit=(params)=>{
    return postRequest(API.bannerEdit,params);
}
// 上傳圖片
export const uploadImage=(params)=>{
    return postFormReq(API.uploadImage,params);
}
// 獲取選擇列表
export const getbannerSelectData=(params)=>{
    return getRequest(API.bannerSelectData,params);
}