<template>
  <div class="mainwrap bannerlist" v-loading="loading">
    <div class="title">
      Banner管理
      <div class="addbtn" v-if="permissionType === 2">
        <el-button  type="success" @click="toAddBanner">新增</el-button>
      </div>
    </div>
    <div class="searchform">
      <el-form
        label-width="80px"
        :inline="true"
        :model="searchForm"
        class="demo-form-inline"
      >
        <el-form-item label="名稱：">
          <el-input v-model="searchForm.Name" placeholder="請輸入名稱"></el-input>
        </el-form-item>
        <el-form-item label="時間：">
          <el-date-picker
            :editable="false"
            v-model="searchForm.Date"
            value-format="yyyy-MM-dd"
            type="daterange"
            start-placeholder="開始時間"
            end-placeholder="結束時間"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button @click="reSet">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="tablewrap">
      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column prop="name" label="名稱"> </el-table-column>
        <el-table-column prop="linkType" label="鏈接類型"> </el-table-column>
        <el-table-column prop="sort" label="排序" width="100"> </el-table-column>
        <el-table-column label="時間">
          <template slot-scope="scope">
            {{scope.row.startTime}} 至 {{scope.row.endTime}}
          </template>
        </el-table-column>
        <el-table-column label="狀態">
          <template slot-scope="scope">
            {{scope.row.isActive==="是"? "已發佈" :"未發佈"}}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="220" v-if="permissionType === 2">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.row)" type="primary" plain>
              编辑
            </el-button>
            <!-- <el-button type="danger" plain>删除</el-button> -->
            <div class="delbtn">
              <el-popconfirm
              confirm-button-text='確定'
              cancel-button-text='取消'
              confirm-button-type="danger"
              icon="el-icon-info"
              icon-color="red"
              title="確定刪除？"
              @confirm="delBanner(scope.row.id)"
            >
                <el-button type="danger" slot="reference">刪除</el-button>
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
        background
        :hide-on-single-page="isSinglePage"
        layout="prev, pager, next,jumper"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        :current-page="currentPage"
        :total="tableDataTotal"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from "./api";
import { getStore} from '@/utils/storage';
export default {
  name: "bannerList",
  data() {
    return {
      loading:false,
      searchForm: {
        Name: "",
        Date: ""
      },
      isSinglePage: false,
      pageSize:10,
      currentPage: 1,
      tableData: [],
      tableDataTotal:0,
      visible:false,
      permissionType: -1
    };
  },
  methods: {
    // getBannerList
    getBannerList(){
      this.loading=true;
      api.getBannerList({
        Name:this.searchForm.Name,
        StartTime:this.searchForm.Date[0],
        EndTime:this.searchForm.Date[1],
        SkipCount:(this.currentPage-1)*this.pageSize,
        MaxResultCount:this.pageSize
      }).then(res=>{
        if(res.systemCode===200){
          this.tableData=res.data.items;
          this.tableDataTotal=res.data.totalCount;
        }
        this.loading=false;
      })
    },
    // 編輯
    handleEdit(item) {
      this.$router.push({
        path: "/cms/banneredit",
        query: {
          type: "edit",
          id: item.id,
        },
      });
    },
    // 新增
    toAddBanner() {
      this.$router.push({
        path: "/cms/banneredit",
        query: {
          type: "create",
        },
      });
    },
    // 刪除
    delBanner(id) {
      api.deleteBanner(id).then(res=>{
        if(res.systemCode===200){
          this.$message.success('刪除成功');
          //如果刪除項为當前頁最后一项
          if (this.tableData.length <= 1) {
            this.currentPage = this.currentPage > 1 ? this.currentPage - 1 : 1;
          }
          this.getBannerList();
        }
      })
    },
    // 翻頁
    handleCurrentChange(val) {
      this.currentPage=val;
      this.getBannerList();
    },
    // 查詢
    onSearch() {
      this.currentPage=1;
      this.getBannerList();
    },
    reSet(){
      this.currentPage=1;
      this.searchForm={
        Name: "",
        Date: "",
      }
      this.getBannerList();
    }
  },
  created(){
    this.getBannerList();
    this.permissionType = parseInt(getStore("permissionType"));
  },
  activated(){
    this.getBannerList();
  }
};
</script>

<style lang="scss" scoped>
.bannerlist {
  .searchform {
    padding: 20px;
    border-bottom: 1px solid #ccc;
  }
  .addbtn {
    width: 100px;
    float: right;
  }
  .delbtn{
    display: inline-block;
    margin-left: 10px;
  }
}
</style>
